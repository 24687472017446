import React, { useEffect } from "react"
import HeroIllustration from "assets/images/hero.png"
import TeamIllustration from "assets/images/team.png"
import AgileIllustration from "assets/images/agile.png"
import DeliveryIllustration from "assets/images/delivery.png"
import ConsultingIcon from "assets/images/consulting.png"
import StrategyIcon from "assets/images/strategy.png"
import DesignIcon from "assets/images/design.png"
import SAASIcon from "assets/images/saas.png"
import SAAPIcon from "assets/images/saap.png"
import ProductsIcon from "assets/images/products.png"
import { ReactComponent as PhoneIcon } from "assets/icons/phone.svg"
import { ReactComponent as EmailIcon } from "assets/icons/email.svg"
import "./Home.scss"

export default function Home() {
  useEffect(() => {
    const details = document.querySelectorAll("details")
    details.forEach((targetDetail) => {
      targetDetail.addEventListener("click", () => {
        details.forEach((detail) => {
          if (detail !== targetDetail) {
            detail.removeAttribute("open")
          }
        })
      })
      return () => {
        targetDetail.removeEventListener("click")
      }
    })
  })

  return (
    <main className="home">
      <section id="hero" className="page-section hero">
        <div className="hero-wrapper">
          <div className="introduction">
            <h1 className="headline">
              Let’s turn your
              <br />
              ideas into reality
            </h1>
            <div className="blurb">
              We are a <b>tech startup studio</b> based in India helping
              startups and businesses ship digital products and bring their
              ideas to life.
            </div>
            <a href="#contact" className="cta free-consultation">
              Get free consultation
            </a>
          </div>
          <div className="illustration">
            <img src={HeroIllustration} alt="hero illustration" />
          </div>
        </div>
      </section>

      <section id="services" className="page-section services">
        <div className="section-summary">
          <h2 className="title">
            What
            <br />
            we offer
          </h2>
          <p className="description">
            We help build companies. We partner with select founders and help
            them bring new ideas to life. Our team is passionate about great
            ideas, self motivated and always ready to learn new things.
          </p>
        </div>
        <div className="services-list">
          <details className="item" open>
            <summary>Technical Consulting</summary>
            <p>
              We will help your idea come to reality with the help of our
              technical expertise.
            </p>
            <img src={ConsultingIcon} alt="technical consulting" />
          </details>
          <details className="item">
            <summary>Product Strategy</summary>
            <p>
              We give our best input and assist you in building the product.
            </p>
            <img src={StrategyIcon} alt="product strategy" />
          </details>
          <details className="item">
            <summary>Product Design</summary>
            <p>We build one of the best UX flow for your product.</p>
            <img src={DesignIcon} alt="product design" />
          </details>
          <details className="item">
            <summary>Software as a Service</summary>
            <p>
              We host a variety of applications which can be used by clients all
              over the internet.
            </p>
            <img src={SAASIcon} alt="software as a service" />
          </details>
          <details className="item">
            <summary>Software as a Product</summary>
            <p>We build your own software based on your custom requirements.</p>
            <img src={SAAPIcon} alt="software as a product" />
          </details>
          <details className="item">
            <summary>Our Products</summary>
            <p>You can contact us to know more about our own products.</p>
            <img src={ProductsIcon} alt="our products" />
          </details>
        </div>
        <div className="contact-link">
          <a href="#contact" className="contact-link-wrapper">
            <span className="label">Contact us</span>
            <i className="line" />
            <i className="arrow" />
          </a>
        </div>
      </section>

      <section id="qualities" className="page-section qualities">
        <ul className="qualities-list">
          <li>
            <div className="illustration">
              <img src={TeamIllustration} alt="Rock Solid Team" />
            </div>
            <div className="bullet-wrapper">
              <i className="bullet small" />
              <i className="bullet large" />
            </div>
            <div className="title">Rock Solid Team</div>
            <div className="description">
              All members of our team are self motivated problem solvers.
            </div>
          </li>
          <li>
            <div className="illustration">
              <img src={AgileIllustration} alt="Agile Development" />
            </div>
            <div className="bullet-wrapper">
              <i className="bullet small" />
              <i className="bullet large" />
            </div>
            <div className="title">Agile Development</div>
            <div className="description">
              We believe in an incremental strategy of learning and adapting.
            </div>
          </li>
          <li>
            <div className="illustration">
              <img src={DeliveryIllustration} alt="On-Time Delivery" />
            </div>
            <div className="bullet-wrapper">
              <i className="bullet small" />
              <i className="bullet large" />
            </div>
            <div className="title">On-Time Delivery</div>
            <div className="description">
              We always deliver our products on time.
            </div>
          </li>
        </ul>
      </section>

      <section id="contact" className="page-section contact">
        <div className="contact-wrapper">
          <div className="title">Get in Touch</div>
          <div className="quick-contact">
            <a href="tel:+919870264671" className="item">
              <span className="icon">
                <PhoneIcon />
              </span>
              <span className="value">+91-9870264671</span>
            </a>
            <a href="mailto:contact@one9.tech" className="item">
              <span className="icon">
                <EmailIcon />
              </span>
              <span className="value">contact@one9.tech</span>
            </a>
          </div>
          <form
            method="post"
            className="contact-form"
            data-netlify-honeypot="bot-field"
            data-netlify="true"
            name="contact-form"
          >
            <input type="hidden" name="form-name" value="contact-form" />
            <div hidden aria-hidden="true">
              <div>
                Don’t fill this out if you're human:
                <input name="bot-field" />
              </div>
            </div>
            <div className="item name">
              <label htmlFor="name">Full Name</label>
              <input
                id="name"
                name="name"
                type="text"
                placeholder="John Doe"
                required
              />
            </div>
            <div className="item phone">
              <label htmlFor="phone">Phone Number</label>
              <input
                id="phone"
                name="phone"
                type="tel"
                pattern="^(\+?)[1-9][0-9 \-\(\)\.]{7,}$"
                required
                placeholder="+12345678901"
              />
            </div>
            <div className="item email">
              <label htmlFor="email">E-mail ID</label>
              <input
                id="email"
                name="email"
                type="email"
                className="email-input-box"
                placeholder="someone@somewhere.com"
                required
              />
            </div>
            <div className="item message">
              <label htmlFor="message">Message</label>
              <textarea
                id="message"
                name="message"
                placeholder="Message"
                rows="4"
              />
            </div>
            <div className="item consent">
              <input id="consent" name="consent" type="checkbox" />
              <label htmlFor="consent">
                I agree to receiving communication from One9 Tech via
                phone/email
              </label>
              <input type="submit" value="Submit" className="cta" />
            </div>
          </form>
        </div>
      </section>
    </main>
  )
}
