import React, { useState, useEffect } from "react";
import _ from "lodash";
import { ReactComponent as Logo } from "assets/logo.svg";
import "./Header.scss";

export default function Header() {
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", _.throttle(handleScroll, 100));
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  const handleScroll = () => {
    window.pageYOffset > "64" ? setIsSticky(true) : setIsSticky(false);
  };

  return (
    <header className={`header ${isSticky ? "sticky" : ""}`}>
      <div className="wrapper">
        <a href="/" className="logo">
          <Logo />
        </a>
        <nav className="navigation">
          <a href="#services" className="item">
            Services
          </a>
          <a href="#contact" className="item">
            Contact
          </a>
        </nav>
      </div>
    </header>
  );
}
