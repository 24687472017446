import React from "react";
// import AnimatedCursor from "react-animated-cursor";
import Home from "./pages/Home";
import Header from "./components/Header";
import Footer from "./components/Footer";
import "./App.css";

function App() {
  return (
    <div className="App">
      {/* <AnimatedCursor color="0, 28, 62" /> */}
      <Header />
      <Home />
      <Footer />
    </div>
  );
}

export default App;
