import React from "react";
import { ReactComponent as GithubIcon } from "assets/icons/github.svg";
import { ReactComponent as MapsIcon } from "assets/icons/maps.svg";
import { ReactComponent as SkypeIcon } from "assets/icons/skype.svg";
import { ReactComponent as LinkedInIcon } from "assets/icons/linked-in.svg";
import "./Footer.scss";

export default function Footer() {
  return (
    <footer className="footer">
      <div className="wrapper">
        <span className="copyright">© 2020 One9 Tech</span>
        <ul className="social">
          <li>
            <a
              href="https://github.com/one9-tech"
              target="_blank"
              rel="noopener noreferrer"
            >
              <GithubIcon />
            </a>
          </li>
          <li>
            <a
              href="https://g.page/one9tech"
              target="_blank"
              rel="noopener noreferrer"
            >
              <MapsIcon />
            </a>
          </li>
          <li>
            <a
              href="skype:jigar_93?chat"
              target="_blank"
              rel="noopener noreferrer"
            >
              <SkypeIcon />
            </a>
          </li>
          <li>
            <a
              href="http://linkedin.com/company/one9-tech"
              target="_blank"
              rel="noopener noreferrer"
            >
              <LinkedInIcon />
            </a>
          </li>
        </ul>
      </div>
    </footer>
  );
}
